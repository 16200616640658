import { ReactNode, useEffect, useRef, useState } from "react";
import { Label } from "../label";
import SearchField from "../search-field";

export type Option = {
    value: string | number;
    label: string;
};

export const SearchInput = ({
    value,
    onChange,
    options,
    label,
    placeholder,
}: {
    value: string | string[] | number | number[] | null;
    onChange: (value?: string | string[] | number | number[] | undefined | null) => any;
    options: Option[];
    label?: ReactNode;
    placeholder?: string;
}) => {
    const [searchText, setSearchText] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(0);

    const searchBoxRef = useRef<HTMLDivElement>(null);
    const dropdownMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setSearchText(options.find((o) => o.value === value)?.label ?? "");
    }, [options, value]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
                setIsDropdownVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchBoxRef]);

    const handleInputChange = (event?: { target: { value: any } }) => {
        if (event && event.target) {
            setSearchText(event.target.value);
            if (event.target.value) {
                setIsDropdownVisible(true);
            } else {
                setIsDropdownVisible(false);
            }
        }
    };

    const handleOptionClick = (value: string | string[] | number | number[] | null | undefined, index: number) => {
        setIsDropdownVisible(false);
        const filteredIndex = options.findIndex((option) => option.label === filteredOptions[index].label);
        onChange(value);
        setHighlightedIndex(index);
        setSearchText(filteredOptions[index].label);
        onChange(options[filteredIndex].value);
    };

    const filteredOptions = options.filter(
        (option) => option.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1,
    );

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isFocused) {
                if (event.key === "ArrowDown") {
                    setHighlightedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0));
                } else if (event.key === "ArrowUp") {
                    setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1));
                } else if (event.key === "Backspace") {
                    setIsDropdownVisible(true);
                } else if (event.key === "Escape") {
                    setIsDropdownVisible(false);
                } else if (event.key === "Enter") {
                    event.preventDefault();
                    if (highlightedIndex !== -1) {
                        const selectedOption = filteredOptions[highlightedIndex];
                        handleOptionClick(selectedOption.value, highlightedIndex);
                    }
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        if (dropdownMenuRef.current && highlightedIndex !== -1) {
            const highlightedItem = dropdownMenuRef.current.childNodes[highlightedIndex] as HTMLElement;
            if (highlightedItem) {
                const menuTop = dropdownMenuRef.current.scrollTop;
                const menuBottom = menuTop + dropdownMenuRef.current.offsetHeight;
                const itemTop = highlightedItem.offsetTop - dropdownMenuRef.current.offsetTop;
                const itemBottom = itemTop + highlightedItem.offsetHeight;
                const itemHeight = highlightedItem.offsetHeight;

                if (itemBottom > menuBottom - 2 * itemHeight) {
                    dropdownMenuRef.current.scrollTop =
                        itemBottom - dropdownMenuRef.current.offsetHeight + 2 * itemHeight;
                } else if (itemTop < menuTop - itemHeight) {
                    dropdownMenuRef.current.scrollTop -= menuTop - itemTop - itemHeight;
                }
            }
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [filteredOptions, highlightedIndex, dropdownMenuRef.current]);

    return (
        <>
            {label && (
                <div style={{ marginBottom: 4 }}>
                    <Label text={label} />
                </div>
            )}

            <div className="relative" ref={searchBoxRef}>
                <SearchField
                    hideMagifyingIcon
                    customBgColor="block w-full rounded-md border-gray-300 pl-2.5 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:text-gray-300 dark:bg-gray-900 dark:border-gray-900"
                    placeholder={placeholder}
                    search={searchText}
                    setSearch={setSearchText}
                    onChange={handleInputChange}
                    onFocus={() => {
                        setIsDropdownVisible(true), setIsFocused(true);
                    }}
                    onBlur={() => setIsFocused(false)}
                />
                {isDropdownVisible && (
                    <div
                        ref={dropdownMenuRef}
                        className="absolute z-10 max-h-40 overflow-auto w-full bg-white dark:bg-gray-900 shadow-lg border border-gray-300 dark:border-gray-900 rounded py-1 mt-1.5 text-sm text-gray-700"
                    >
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option, index) => (
                                <div
                                    key={option.value}
                                    className={`px-2 py-2 cursor-pointer select-none truncate rounded block transition duration-300 ${
                                        index === highlightedIndex
                                            ? "bg-blue-300 text-gray-500 dark:text-gray-900"
                                            : "text-gray-500 dark:text-gray-400 hover:bg-blue-300 hover:text-gray-500 hover:dark:text-gray-900"
                                    }`}
                                    onClick={() => handleOptionClick(option.value, index)}
                                    data-index={index}
                                >
                                    {option.label}
                                </div>
                            ))
                        ) : (
                            <div className="px-2 py-2 text-gray-400">No options found</div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
