import { z } from "zod";
import ModalDialog from "../../layout/modal-dialog";
import { Form } from "../../layout/form/form";
import { EmailField } from "../../layout/form/email-field";
import { useForm } from "../../hooks/useForm";
import { Search } from "../../layout/form/searchField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createUserCompany } from "../../user-companies/actions/create-user-company";
import { getUsers } from "../../jason-proof-of-concept/users/actions/get-users";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { ClearERCUser } from "../../typings/api/clear-erc-user";
import ButtonNeoGen from "../../layout/button-neogen";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";
import { useState } from "react";
import { TextField } from "../../layout/form/text-field";
import { createMagicLink } from "../../magic-links/actions/create-magic-link";
import { MagicLinkTypes } from "../../magic-links/domain/magic-link";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { CheckboxField } from "../../layout/form/checkbox-field";
import { createUser } from "../../jason-proof-of-concept/users/actions/create-user";
import roleGroupService from "../../services/role-group.service";
import { User } from "../../jason-proof-of-concept/users/domain/user";

const initialFormSchema = z.object({
    email: z.string().email(),
    roleId: z.number(),
});

type InitialFormData = z.infer<typeof initialFormSchema>;

const inviteFormData = z.object({
    email: z.string().email(),
    roleId: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    inviteUser: z.boolean().optional(),
});

type InviteFormData = z.infer<typeof inviteFormData>;

export const AddUserToCompanyModal = ({
    onClose,
    companyId,
    onUserAdded,
    companyRoles,
}: {
    onClose: () => any;
    companyId: number;
    onUserAdded: () => any;
    companyRoles: NonNullable<User["companyRoleGroups"]>;
}) => {
    const [user] = useRecoilState(userAtom);
    if (!user.id) {
        throw new Error("User not defined.");
    }
    const [stage, setStage] = useState<"initial" | "inviteExistingUser" | "inviteNewUser">("initial");
    const initialForm = useForm({ schema: initialFormSchema });
    const inviteForm = useForm({ schema: inviteFormData });
    const authToken = getAuthTokenNoThrow() || "no-token";

    const initialMutation = useMutation({
        mutationFn: async (data: InitialFormData) => {
            const [user] = await getUsers({ authToken, filters: { where: { email: data.email } } });
            if (!user) {
                return {};
            }
            const [existingUserCompany] = await getUserCompanies({
                authToken,
                filters: { where: { ercUserId: user.id, companyId } },
            });
            if (existingUserCompany) {
                throw new Error("User already in company.");
            }
            const userCompany = await createUserCompany({
                authToken,
                data: { userId: user.id, companyId, roleId: data.roleId },
            });
            return { user, userCompany };
        },
    });

    const handleInitialSubmit = async (data: InitialFormData) => {
        const { user, userCompany } = await initialMutation.mutateAsync(data);
        if (userCompany) {
            onUserAdded();
        }
        if (!user && !userCompany) {
            setStage("inviteNewUser");
            inviteForm.setValue("email", data.email);
            inviteForm.setValue("roleId", data.roleId);
        }
    };

    const inviteMutation = useMutation({
        mutationFn: async (data: InviteFormData) => {
            await createMagicLink({
                authToken,
                data: { type: MagicLinkTypes.userCompanyInvite, createdById: user.id as string, data: { ...data } },
            });
        },
    });

    const addUserMutation = useMutation({
        mutationFn: async (data: InviteFormData) => {
            const user = await createUser({
                authToken,
                data: { email: data.email, firstName: data.firstName, lastName: data.lastName, phone: data.phone },
            });
            const userCompany = await createUserCompany({
                authToken,
                data: { userId: user.id, companyId, roleId: data.roleId },
            });
            return { user, userCompany };
        },
    });

    const handleInviteSubmit = async (data: InviteFormData) => {
        if (data.inviteUser) {
            await inviteMutation.mutateAsync(data);
        } else {
            await addUserMutation.mutateAsync(data);
        }
        onUserAdded();
    };

    const options = companyRoles.map((role) => ({ label: role.name || "", value: role.id as number }));

    return (
        <ModalDialog title="Add user to company" close={onClose} show showOk={false} showCancel={false}>
            <div className="pt-6">
                {stage === "initial" && (
                    <Form
                        onSubmit={initialForm.handleSubmit(handleInitialSubmit)}
                        error={(addUserMutation.error || inviteMutation.error) as any}
                    >
                        <EmailField
                            autoComplete="off"
                            label="Enter user email address"
                            {...initialForm.getFieldProps("email")}
                        />
                        <Search label="Select role" {...initialForm.getFieldProps("roleId")} options={options} />
                        <ButtonNeoGen block type="submit" disabled={initialMutation.isLoading}>
                            Continue
                        </ButtonNeoGen>
                    </Form>
                )}
                {stage === "inviteNewUser" && (
                    <Form onSubmit={inviteForm.handleSubmit(handleInviteSubmit)} error={inviteMutation.error as any}>
                        <EmailField
                            autoComplete="off"
                            label="Enter user email address"
                            {...inviteForm.getFieldProps("email")}
                        />
                        <Search label="Select role" {...inviteForm.getFieldProps("roleId")} options={options} />
                        <TextField label="First name" {...inviteForm.getFieldProps("firstName")} />
                        <TextField label="Last name" {...inviteForm.getFieldProps("lastName")} />
                        <TextField label="Phone number" {...inviteForm.getFieldProps("phone")} />
                        <CheckboxField label="Invite user to system?" {...inviteForm.getFieldProps("inviteUser")} />
                        <ButtonNeoGen block type="submit" disabled={inviteMutation.isLoading}>
                            Add user
                        </ButtonNeoGen>
                    </Form>
                )}
            </div>
        </ModalDialog>
    );
};
