import Swal from "sweetalert2";

export default function SwalNeogenFire(config: any) {
    // This function wraps the Swal alerts and provides exactly the same functionality
    // as the original Swal with the exception of providing dark mode support.
    if (config.darkMode) {
        // If dark mode is enabled, we need to override the default Swal styling
        // with our own dark mode styling.
        const { icon, ...rest } = config;
        const darkModeConfig = {
            ...rest,
            icon: icon || "info",
            customClass: {
                container: "dark:bg-gray-800 dark:text-gray-100",
                popup: "dark:bg-gray-800 dark:text-gray-100",
                header: "dark:bg-gray-800 dark:text-gray-100",
                title: "dark:bg-gray-800 dark:text-gray-100",
                closeButton: "dark:bg-gray-800 dark:text-gray-100",
                icon: "dark:bg-gray-800 dark:text-gray-100",
                image: "dark:bg-gray-800 dark:text-gray-100",
                content: "dark:bg-gray-800 dark:text-gray-100",
                input: "dark:bg-gray-800 dark:text-gray-100",
                actions: "dark:bg-gray-800 dark:text-gray-100",
                confirmButtonText: "dark:bg-gray-800 dark:text-gray-100",
                cancelButtonText: "dark:bg-gray-800 dark:text-gray-100",
                confirmButton: "dark:bg-gray-800 dark:text-gray-100",
                cancelButton: "dark:bg-gray-800 dark:text-gray-100",
                footer: "dark:bg-gray-800 dark:text-gray-100",
            },
        };
        return Swal.fire(darkModeConfig);
    }

    const {
        title,
        text,
        icon,
        confirmButtonText,
        showCancelButton,
        cancelButtonText,
        showLoaderOnConfirm,
        preConfirm,
        onConfirm,
        onCancel,
        ...rest
    } = config;

    return Swal.fire({
        title,
        text,
        icon,
        confirmButtonText,
        showCancelButton,
        cancelButtonText,
        showLoaderOnConfirm,
        preConfirm,
        onConfirm,
        onCancel,
        ...rest,
    });
}

type SwalProps = {
    title: string;
    text: string;
    type: "success" | "error" | "warning" | "info" | "question";
    showCancelButton: boolean;
};
